import { DialogService }               from 'aurelia-dialog';
import { bindable, inject }            from 'aurelia-framework';
import { I18N }                        from 'aurelia-i18n';
import { BaseListViewModel }           from 'base-list-view-model';
import { AddBulletinsToListingsModal } from 'modules/management/specifications/listings/add-bulletins-to-listings-modal';
import { DuplicateModal }              from 'modules/management/specifications/listings/duplicate-modal';
import { FilterFormSchema }            from 'modules/management/specifications/listings/filter-form-schema';
import { ListingRevisionsRepository }  from 'modules/management/specifications/listings/listing-revisions/services/repository';
import { AppContainer }                from 'resources/services/app-container';
import { Downloader }                  from 'resources/services/downloader';
import { ListingsRepository }          from './services/repository';

@inject(AppContainer, ListingsRepository, ListingRevisionsRepository, I18N, Downloader, FilterFormSchema, DialogService)
export class ListListings extends BaseListViewModel {

    listingId = 'management-specifications-listings-listing';

    @bindable headerTitle    = 'listing.management.specifications.listings';
    @bindable newRecordRoute = 'management.specifications.listings.create';
    @bindable repository;
    @bindable datatable;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param listingRevisionsRepository
     * @param i18N
     * @param downloader
     * @param filterFormSchema
     * @param dialogService
     */
    constructor(appContainer, repository, listingRevisionsRepository, i18N, downloader, filterFormSchema, dialogService) {
        super(appContainer);

        this.repository                 = repository;
        this.listingRevisionsRepository = listingRevisionsRepository;
        this.i18N                       = i18N;
        this.downloader                 = downloader;
        this.filterFormSchema           = filterFormSchema;
        this.dialogService              = dialogService;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.specifications.listings.manage',
            'management.specifications.listings.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        this.defineDatatable();

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:         this.repository,
            show:               {
                action:  (row) => this.appContainer.router.navigateToRoute('management.specifications.listings.view', { id: row.listing_id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.specifications.listings.manage', 'management.specifications.listings.view']),
            },
            edit:               {
                action:  (row) => this.appContainer.router.navigateToRoute('management.specifications.listings.edit', { id: row.listing_id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.specifications.listings.manage', 'management.specifications.listings.edit']),
            },
            destroy:            {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => this.appContainer.authenticatedUser.can(['management.specifications.listings.manage', 'management.specifications.listings.delete']),
            },
            options:            [
                {
                    label:   'form.button.add-bulletins-to-listings',
                    icon:    'icon-file-plus',
                    action:  () => this.openModal(null, AddBulletinsToListingsModal),
                    visible: (row) => this.appContainer.authenticatedUser.can(['management.specifications.listings.manage', 'management.specifications.listings.edit']),
                },
                {
                    label:   'form.button.export-to-excel',
                    icon:    'icon-file-excel',
                    action:  () => this.exportListing(this.i18N.tr('form.field.listings'), !this.datatable.instance.selectedRows.length ? null : this.datatable.instance.selectedRows),
                    visible: (row) => this.appContainer.authenticatedUser.can(['management.specifications.listings.manage', 'management.specifications.listings.view']),
                },
            ],
            actions:            [
                {
                    icon:    'icon-copy3',
                    action:  (row) => this.duplicateListing(row),
                    tooltip: 'form.button.duplicate',
                    visible: (row) => this.appContainer.authenticatedUser.can(['management.specifications.listings.manage', 'management.specifications.listings.create']),
                },
            ],
            actionsContextMenu: true,
            selectable:         true,
            destroySelected:    true,
            sorting:            [
                {
                    column:    0,
                    direction: 'asc',
                },
                {
                    column:    4,
                    direction: 'asc',
                },
            ],
            columns:            [
                {
                    data:  'number',
                    name:  'listings.number',
                    title: 'form.field.number',
                },
                {
                    data:  'concession',
                    name:  'concessions.acronym',
                    title: 'form.field.concession-code',
                },
                {
                    data:  'designation',
                    name:  'listing_translations.designation',
                    title: 'form.field.designation',
                },
                {
                    data:  'sector',
                    name:  'sector_translations.description',
                    title: 'form.field.sector',
                },
                {
                    data:  'revision_number',
                    name:  'listing_revisions.revision_number',
                    title: 'form.field.revision-number',
                },
                {
                    data:    'observations',
                    name:    'listings.observations',
                    title:   'form.field.observations',
                    display: false,
                },
                {
                    data:  'created_by',
                    name:  'users.name',
                    title: 'form.field.created-by',
                },
                {
                    data:            'created_at',
                    name:            'listings.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
            ],
        };
    }

    /**
     * Opens the given modal
     */
    openModal(model, viewModel) {
        return this.appContainer.dialogService
            .open({ viewModel: viewModel, model: model })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    this.datatable.instance.reload();
                }
            });
    }

    duplicateListing(model) {
        return this.dialogService.open({
            viewModel: DuplicateModal,
            model:     model,
        }).whenClosed((response) => {
            if (!response.wasCancelled) {
                this.datatable.instance.reload();

                if (response.output !== null) {
                    this.appContainer.router.navigateToRoute('management.specifications.listings.edit', { id: response.output });
                }
            }
        });
    }

}
