import { EventAggregator }       from 'aurelia-event-aggregator';
import { inject }                from 'aurelia-framework';
import { I18N }                  from 'aurelia-i18n';
import { ConcessionsRepository } from 'modules/management/specifications/concessions/services/repository';
import { SectorsRepository }     from 'modules/management/specifications/sectors/services/repository';
import { BaseFilterFormSchema }  from 'resources/classes/base-filter-form-schema';
import { SessionStorage }        from 'resources/services/session-storage';

@inject(SessionStorage, EventAggregator, I18N, ConcessionsRepository, SectorsRepository)
export class FilterFormSchema extends BaseFilterFormSchema {

    /**
     * Model default values
     *
     * @type {{}}
     */
    modelDefaults = {
        created_by:      [],
        concessions_ids: [],
        sectors_ids:     [],
        created_at_from: null,
        created_at_to:   null,
    };

    /**
     * Constructor
     *
     * @param storage
     * @param eventAggregator
     * @param i18n
     * @param concessionsRepository
     * @param sectorsRepository
     */
    constructor(storage, eventAggregator, i18n, concessionsRepository, sectorsRepository) {
        super(storage, eventAggregator, i18n);

        this.concessionsRepository = concessionsRepository;
        this.sectorsRepository     = sectorsRepository;
    }

    /**
     * Returns a new instance of the model
     *
     * @returns
     */
    model(viewModel) {
        return super.filterModel(viewModel, this.modelDefaults);
    }

    /**
     * Returns form schema
     *
     * @param viewModel
     *
     * @returns {*[]}
     */
    schema(viewModel) {
        this.number = {
            type:     'number',
            key:      'number',
            label:    'form.field.number',
            size:     4,
            required: false,
        };

        this.concession_code = {
            type:     'text',
            key:      'concession_code',
            label:    'form.field.concession-code',
            size:     4,
            required: false,
        };

        this.test_code = {
            type:     'text',
            key:      'test_code',
            label:    'form.field.bulletin-code',
            size:     4,
            required: false,
        };

        this.designation = {
            type:     'text',
            key:      'designation',
            label:    'form.field.designation',
            size:     4,
            required: false,
        };

        this.concessions_ids = {
            type:         'multiselect-native',
            key:          'concessions_ids',
            label:        'form.field.concessions',
            size:         4,
            remoteSource: () => this.concessionsRepository.all(),
            required:     false,
        };

        this.sectors_ids = {
            type:         'multiselect-native',
            key:          'sectors_ids',
            label:        'form.field.sectors',
            size:         4,
            remoteSource: () => this.sectorsRepository.all(),
            required:     false,
        };

        this.revisions_in_effect = {
            type:     'checkbox',
            key:      'revisions_in_effect',
            label:    'form.field.revisions-in-effect',
            size:     4,
            required: false,
        };

        this.created_by = {
            type:     'users-multiselect',
            key:      'created_by',
            label:    'form.field.created-by',
            size:     4,
            required: false,
        };

        this.created_at_from = {
            type:     'material-ui-date-picker',
            key:      'created_at_from',
            label:    'form.field.created-at(from)',
            size:     4,
            required: false,
        };

        this.created_at_to = {
            type:     'material-ui-date-picker',
            key:      'created_at_to',
            label:    'form.field.created-at(to)',
            size:     4,
            required: false,
        };

        this.searchButton = {
            type:       'submit',
            label:      'form.button.search',
            action:     () => this.eventAggregator.publish('datatable-must-be-reloaded', {
                listingId: viewModel.listingId,
                criteria:  viewModel.filterModel,
            }),
            attributes: {
                class: 'btn',
                style: 'background-color: #0092D5 !important; border-color: #0092D5 !important; color: #ffffff !important;',
            },
            icon:       {
                attributes: {
                    class: 'icon-search4',
                },
            },
        };

        this.clearButton = {
            type:       'button',
            label:      'form.button.clear',
            action:     () => this.eventAggregator.publish('datatable-filter-must-be-reseted', viewModel.listingId),
            attributes: {
                class: 'btn btn-light filter-reset',
            },
            icon:       {
                attributes: {
                    class: 'icon-close2',
                },
            },
        };

        this.buttons = {
            type:    'buttons',
            actions: [
                this.searchButton,
                this.clearButton,
            ],
        };

        return [
            [this.number, this.concession_code, this.designation],
            [this.test_code, this.concessions_ids, this.sectors_ids],
            [this.revisions_in_effect],
            [this.created_by, this.created_at_from, this.created_at_to],
            [this.buttons],
        ];
    }
}
